.logo {
  margin: 45px 0 41px 0;
}
@media screen and (max-width: 880px) {
  .logo {
    margin: 28px 0 32px 27px;
    width: 126px;
    height: 24px;
  }
}
