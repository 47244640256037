.popup__form-input {
    outline: none;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 358px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 13px;
  }

@media screen and (max-width: 680px) {
    .popup__form-input {
      max-width: 238px;
    }
  }