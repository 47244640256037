.gallery {
  max-width: 880px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 17px;
  row-gap: 21px;
  justify-content: center;
}
@media screen and (max-width: 880px) {
  .gallery {
    margin: 0 19px;
  }
}
