.auth-form__input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 2px solid #ccc;
  height: 30px;
  margin-bottom: 30px;
  color: #fff;
}
.auth-form__input::placeholder {
  font-size: 14px;
  line-height: 17px;
  color: #cccccc;
}
