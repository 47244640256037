.card__like-button {
  height: 19px;
  width: 22px;
  background-color: transparent;
  background-image: url("../../../images/likeButton.svg");
  background-size: cover;
  border: none;
  cursor: pointer;
}
.card__like-button:hover {
  opacity: 0.5;
}
