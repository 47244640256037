.popup__container {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 430px;
  padding: 36px;
  width: 100%;
}
.popup_type_image .popup__container {
  max-width: fit-content;
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
}
@media screen and (max-width: 680px) {
  .popup__container {
    max-width: 282px;
    box-sizing: border-box;
    padding: 25px 22px;
  }
}
