.popup__close-button {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -42px;
  top: -42px;
  background-color: transparent;
  background-image: url("../../../images/closeButton.svg");
  background-size: cover;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 680px) {
  .popup__close-button {
    right: 0;
    top: -50px;
    width: 26px;
    height: 26px;
  }
}
.popup__close-button:hover {
  opacity: 0.6;
}
