.popup__info-container {
  position: relative;
  margin: auto;
  flex-direction: column;
  width: 430px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  padding: 36px;
  display: flex;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 450px) {
  .popup__info-container {
    max-width: 90%;
    width: 100%;
  }
}
