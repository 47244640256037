.user__name {
    margin: 0;
    max-width: 450px;
    font-weight: 500;
    font-size: 42px;
    line-height: 48px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media screen and (max-width: 880px) {
    .user__name {
        max-width: 270px;
      font-size: 22px;
      line-height: 27px;
    }
  }