.popup__form-button {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 2px;
  min-height: 50px;
  cursor: pointer;
  margin-top: 31px;
}
.popup__form-button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 880px) {
  .popup__form-button {
    font-size: 14px;
    line-height: 17px;
  }
}
