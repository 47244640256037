.popup__form-button_disabled {
  opacity: 0.2;
  border: 1px solid #000000;
  background-color: #fff;
  color: #000;
}

.popup__form-button_disabled:hover {
  opacity: 0.2;
  border: 1px solid #000000;
  background-color: #fff;
  color: #000;
  cursor: default;
}
