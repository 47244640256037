.user__profession {
  margin: 16px 0 0 0;
  font-weight: 400;
  font-size: 18px;
  max-width: 270px;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 880px) {
  .user__profession {
    font-size: 14px;
    line-height: 17px;
  }
}
