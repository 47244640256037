.user__avatar-overlay {
  display: flex;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  transition: opacity 0.3s linear;
}
