.auth-form__button {
    width: 100%;
    height: 50px;
    background-color: #fff;
    margin: 185px 0 15px 0;
    border: none;
    border-radius: 2px;
    color: #000;
    font-weight: 400;
  }
  .auth-form__button:hover {
    opacity: 0.85;
  }
  @media screen and (max-width: 600px) {
    .auth-form__button {
      margin-top: 85px;
    }
  }