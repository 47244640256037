.card__delete-button {
    width: 18px;
    height: 20px;
    position: absolute;
    top: 18px;
    right: 15px;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}
.card__delete-button:hover {
    opacity: .6;
}