.popup__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin: 0 0 50px 0;
}
@media screen and (max-width: 880px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
  }
}
